import React, { useState } from 'react';

const BuildingInPublic = ({ onClose }) => {
  const [isMaximized, setIsMaximized] = useState(true);
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 300);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 p-6">
      <div className={`bg-black/30 backdrop-blur-md rounded-xl shadow-2xl border border-white/10 
                    transition-all duration-300 ease-out
                    ${isMaximized ? 'w-full h-full' : 'w-[800px] h-[600px]'}
                    ${isClosing ? 'scale-95 opacity-0' : 'scale-100 opacity-100'}`}>
        <div className="flex items-center p-3 border-b border-white/10">
          <div className="flex gap-2">
            <button onClick={handleClose} 
                    className="w-3 h-3 rounded-full bg-red-500 hover:bg-red-600 relative group transition-colors">
              <span className="absolute inset-0 flex items-center justify-center text-[13px] text-black/80 opacity-0 group-hover:opacity-100">×</span>
            </button>
            <button onClick={handleClose}
                    className="w-3 h-3 rounded-full bg-yellow-500 hover:bg-yellow-600 relative group transition-colors">
              <span className="absolute inset-0 flex items-center justify-center text-[13px] text-black/80 opacity-0 group-hover:opacity-100">−</span>
            </button>
            <button onClick={() => setIsMaximized(!isMaximized)}
                    className="w-3 h-3 rounded-full bg-green-500 hover:bg-green-600 relative group transition-colors">
              <span className="absolute inset-0 flex items-center justify-center text-[13px] text-black/80 opacity-0 group-hover:opacity-100">+</span>
            </button>
            <span className="ml-4 text-white/60 text-sm font-mono">The Art of Building in Public</span>
          </div>
        </div>

        <div className="p-8 text-white/90 h-[calc(100%-3rem)] overflow-y-auto">
          <div className="max-w-3xl mx-auto">
            <h1 className="text-4xl font-bold mb-2 font-mono">The Art of Building in Public</h1>
            <div className="text-white/60 mb-8 font-mono">December 1, 2023 • 5 min read</div>
            
            <div className="prose prose-invert prose-lg">
              <p className="text-white/80 leading-relaxed mb-6">
                Building in public isn't just about sharing progress—it's about creating a narrative that others can learn from and contribute to. The transparency creates accountability and fosters genuine connections within the community.
              </p>
              
              <h2 className="text-2xl font-bold mt-8 mb-4">Why Build in Public?</h2>
              <p className="text-white/80 leading-relaxed mb-6">
                When we share our process, we invite collaboration, feedback, and serendipitous connections. It transforms solo projects into community endeavors, where each milestone becomes a shared achievement.
              </p>
              
              <div className="bg-black/30 backdrop-blur-md rounded-lg p-6 my-8 border border-white/10">
                <blockquote className="text-xl text-white/90 italic">
                  "The best way to learn is to build something and share it with others."
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildingInPublic;