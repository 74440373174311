import React, { useState } from 'react';

const ProjectAurora = ({ onClose }) => {
  const [isMaximized, setIsMaximized] = useState(true);
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 300);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 p-6">
      <div className={`bg-black/30 backdrop-blur-md rounded-xl shadow-2xl border border-white/10 
                    transition-all duration-300 ease-out
                    ${isMaximized ? 'w-full h-full' : 'w-[800px] h-[600px]'}
                    ${isClosing ? 'scale-95 opacity-0' : 'scale-100 opacity-100'}`}>
        <div className="flex items-center p-3 border-b border-white/10">
          <div className="flex gap-2">
            <button onClick={handleClose} 
                    className="w-3 h-3 rounded-full bg-red-500 hover:bg-red-600 relative group transition-colors">
              <span className="absolute inset-0 flex items-center justify-center text-[13px] text-black/80 opacity-0 group-hover:opacity-100">×</span>
            </button>
            <button onClick={handleClose}
                    className="w-3 h-3 rounded-full bg-yellow-500 hover:bg-yellow-600 relative group transition-colors">
              <span className="absolute inset-0 flex items-center justify-center text-[13px] text-black/80 opacity-0 group-hover:opacity-100">−</span>
            </button>
            <button onClick={() => setIsMaximized(!isMaximized)}
                    className="w-3 h-3 rounded-full bg-green-500 hover:bg-green-600 relative group transition-colors">
              <span className="absolute inset-0 flex items-center justify-center text-[13px] text-black/80 opacity-0 group-hover:opacity-100">+</span>
            </button>
            <span className="ml-4 text-white/60 text-sm font-mono">Project Aurora</span>
          </div>
        </div>

        <div className="p-8 text-white/90 h-[calc(100%-3rem)] overflow-y-auto">
          <h1 className="text-4xl font-bold mb-6 font-mono">Project Aurora</h1>
          
          <div className="space-y-6">
            <div className="bg-black/30 backdrop-blur-md rounded-lg p-6 border border-white/10">
              <h2 className="text-xl font-bold mb-4">Overview</h2>
              <p className="text-white/80">An AI-powered assistant designed to enhance creative workflows through natural language processing and contextual understanding.</p>
            </div>

            <div className="bg-black/30 backdrop-blur-md rounded-lg p-6 border border-white/10">
              <h2 className="text-xl font-bold mb-4">Technical Stack</h2>
              <div className="flex gap-3 flex-wrap">
                {["React", "Python", "TensorFlow", "GPT-4"].map(tech => (
                  <span key={tech} className="px-3 py-1 bg-blue-500/20 rounded-full text-blue-400 text-sm">
                    {tech}
                  </span>
                ))}
              </div>
            </div>

            <div className="bg-black/30 backdrop-blur-md rounded-lg p-6 border border-white/10">
              <h2 className="text-xl font-bold mb-4">Key Features</h2>
              <ul className="space-y-3 text-white/80">
                <li>• Natural language task interpretation</li>
                <li>• Context-aware suggestions</li>
                <li>• Workflow automation</li>
                <li>• Real-time collaboration</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectAurora;