import React, { useState, useEffect } from 'react';
import { CalendarDays } from 'lucide-react';
import ProjectAurora from './Laboratory/ProjectAurora';

const WorksContent = ({ isOpen, onClose }) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [activeProject, setActiveProject] = useState(null);
  const [showMainWindow, setShowMainWindow] = useState(true);

  useEffect(() => {
    if (!isOpen) {
      setIsMinimized(false);
      setIsMaximized(false);
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 300);
  };

  const handleProjectClick = (project) => {
    setShowMainWindow(false);
    switch(project) {
      case "Project Aurora":
        setActiveProject(<ProjectAurora onClose={() => {
          setActiveProject(null);
          setShowMainWindow(true);
        }} />);
        break;
      default:
        break;
    }
  };

  if (!isOpen) return null;

  return (
    <>
      {activeProject}
      {showMainWindow && (
        <div className={`fixed inset-0 flex items-center justify-center z-50 transition-all duration-300 p-6
                        ${!showMainWindow ? 'opacity-0 scale-95 pointer-events-none' : 'opacity-100 scale-100'}`}>
          <div 
            className={`bg-black/30 backdrop-blur-md rounded-xl shadow-2xl border border-white/10 
                      transition-all duration-300 ease-out
                      ${isMaximized ? 'w-full h-full rounded-none' : 'w-[800px] h-[600px]'}
                      ${isMinimized ? 'scale-0 opacity-0' : 'scale-100 opacity-100'}
                      ${isClosing ? 'scale-95 opacity-0' : ''}`}
          >
            <div className="flex items-center p-3 border-b border-white/10">
              <div className="flex gap-2">
                <button 
                  onClick={handleClose}
                  className="w-3 h-3 rounded-full bg-red-500 hover:bg-red-600 relative group transition-colors"
                >
                  <span className="absolute inset-0 flex items-center justify-center text-[13px] text-black/80 opacity-0 group-hover:opacity-100">×</span>
                </button>
                <button
                  onClick={handleClose}
                  className="w-3 h-3 rounded-full bg-yellow-500 hover:bg-yellow-600 relative group transition-colors"
                >
                  <span className="absolute inset-0 flex items-center justify-center text-[13px] text-black/80 opacity-0 group-hover:opacity-100">−</span>
                </button>
                <button
                  onClick={() => setIsMaximized(!isMaximized)}
                  className="w-3 h-3 rounded-full bg-green-500 hover:bg-green-600 relative group transition-colors"
                >
                  <span className="absolute inset-0 flex items-center justify-center text-[13px] text-black/80 opacity-0 group-hover:opacity-100">+</span>
                </button>
              </div>
            </div>
            
            <div className="p-8 text-white/90 h-[calc(100%-3rem)] overflow-y-auto">
              <h2 className="font-mono text-3xl mb-2">Laboratory_</h2>
              <p className="font-mono text-sm text-white/50 mb-8">experiments & explorations in code</p>
              
              <div className="space-y-8">
                {works.map((work, index) => (
                  <div key={index} 
                    onClick={() => handleProjectClick(work.title)}
                    className="border border-white/10 rounded-lg p-6 backdrop-blur-sm hover:bg-white/5 transition-all cursor-pointer">
                    <div className="flex items-center justify-between mb-2">
                      <h3 className="font-mono text-xl">{work.title}</h3>
                      <div className="flex items-center gap-2 text-white/50">
                        <CalendarDays className="w-4 h-4" />
                        <span className="font-mono text-sm">{work.year}</span>
                      </div>
                    </div>
                    <p className="font-mono text-sm text-white/70 mb-4">{work.description}</p>
                    <div className="flex flex-wrap gap-3">
                      {work.tech.map((tech, i) => (
                        <span key={i} 
                          className="font-mono text-xs bg-white/10 px-3 py-1 rounded-full hover:bg-white/20 transition-colors">
                          {tech}
                        </span>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const works = [
  {
    title: "Project Aurora",
    description: "AI-powered personal assistant for creative professionals",
    tech: ["React", "Python", "TensorFlow"],
    year: "2023"
  },
  {
    title: "Nexus Hub", 
    description: "Decentralized marketplace for digital artists",
    tech: ["Solidity", "Next.js", "IPFS"],
    year: "2023"
  },
  {
    title: "Mindscape",
    description: "Virtual reality meditation and wellness platform",
    tech: ["Unity", "C#", "WebXR"],
    year: "2022"
  }
];

export default WorksContent;