import React, { useState } from 'react';
import { Twitter, MessageCircle } from 'lucide-react';

const ContactWindow = ({ isOpen, onClose }) => {
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 300);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className={`bg-black/30 backdrop-blur-md rounded-xl shadow-2xl border border-white/10 
                    w-[400px] transition-all duration-300 ${isClosing ? 'scale-95 opacity-0' : 'scale-100 opacity-100'}`}>
        <div className="flex items-center p-3 border-b border-white/10">
          <div className="flex gap-2">
            <button onClick={handleClose} className="w-3 h-3 rounded-full bg-red-500 hover:bg-red-600 relative group">
            <span className="absolute inset-0 flex items-center justify-center text-[13px] text-black/80 opacity-0 group-hover:opacity-100">×</span>
            </button>
          </div>
        </div>
        
        <div className="p-8 space-y-4">
          <h2 className="text-xl text-white/90 font-mono mb-6">Let's connect_</h2>
          
          <a href="https://x.com/azharaio" 
             target="_blank" 
             rel="noopener noreferrer"
             className="flex items-center gap-3 p-4 rounded-lg border border-white/10 hover:bg-white/5 transition-all text-white/80">
            <Twitter className="w-5 h-5" />
            <span>DM on Twitter/X</span>
          </a>
          
          <a href="https://discord.com/users/azharaio" 
             target="_blank"
             rel="noopener noreferrer" 
             className="flex items-center gap-3 p-4 rounded-lg border border-white/10 hover:bg-white/5 transition-all text-white/80">
            <MessageCircle className="w-5 h-5" />
            <span>Message on Discord</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactWindow