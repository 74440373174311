import React, { useEffect, useState } from 'react';

const FullscreenAlert = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    const checkSize = () => {
      const minWidth = window.innerWidth < 900;
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      setShowAlert(minWidth || isMobile);
    };

    checkSize();
    setMounted(true);
    window.addEventListener('resize', checkSize);
    return () => window.removeEventListener('resize', checkSize);
  }, []);

  if (!showAlert) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/20 backdrop-blur-sm">
      <div className={`transform transition-all duration-300 ${mounted ? 'scale-100 opacity-100' : 'scale-95 opacity-0'}`}>
        <div className="bg-white/10 backdrop-blur-2xl border border-white/20 rounded-xl w-[340px] overflow-hidden shadow-2xl">
          <div className="px-6 pt-6 pb-4">
            <div className="w-12 h-12 bg-gradient-to-br from-red-400 to-red-500 rounded-full mx-auto mb-4 flex items-center justify-center backdrop-blur-xl">
              <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
            <h3 className="text-lg font-semibold text-white text-center mb-2">View in Full Screen</h3>
            <p className="text-white/90 text-center text-sm mb-6">
              For the best experience, please view this site in full screen mode.
            </p>
          </div>
          <div className="border-t border-white/20">
            <button 
              onClick={() => setShowAlert(false)}
              className="w-full px-6 py-3 text-blue-400 hover:bg-white/10 transition-colors text-sm font-semibold"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullscreenAlert;